<template>
  <v-container>
    <div class="d-flex mb-4">
      <h2>Manage Users</h2>
    </div>
    <v-data-table :items="users" :headers="headers" :disable-pagination="true" :hide-default-footer=true dense>
      <template v-slot:[`item.xeroContactId`]="{ item }">
        <a
          target="_blank"
          v-bind:href="
            'https://go.xero.com/Contacts/View/' + item.xeroContactId
          "
          >Xero</a
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import UserApi from '@/api/user'

export default {
  data() {
    return {
      users: [],
      headers: [
        { text: 'Name', value: 'contact.name' },
        { text: 'Customer', value: 'contact.isCustomer'},
        { text: 'Supplier', value: 'contact.isSupplier'},
        { text: 'Xero', value: 'xeroContactId'},
        { text: 'Artist', value: 'artist'},
        { text: 'Marketing', value: 'contact.marketingCategory' },
        { text: 'Access', value: 'accessRoles' },
      ],
      mode: 'preview'
    }
  },
  async created() {
    await this.loadUsers()
  },
  mounted: function () {
  },
  computed: {
  },
  watch: {},
  methods: {
    loadUsers() {
      UserApi.getUsers().then((results) => {
        this.users = results.data
        this.loadingData = false
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
